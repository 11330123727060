import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type CompleteInvitationDto = {
  invitationToken: Scalars['String'];
  password: Scalars['String'];
};

export type CreateDeviceDto = {
  hardwareId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDeviceUserDto = {
  firstName: Scalars['String'];
  hasBasicAccess: Scalars['Boolean'];
  hasDistributionAccess: Scalars['Boolean'];
  identifier: Scalars['String'];
  lastName: Scalars['String'];
  pin: Scalars['String'];
  tag: Scalars['String'];
};

export type DepartmentDto = {
  name: Scalars['String'];
};

export type DepartmentList = {
  __typename?: 'DepartmentList';
  list: Array<DepartmentType>;
  totalCount: Scalars['Int'];
};

export type DepartmentSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DepartmentType = {
  __typename?: 'DepartmentType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DepartmentWhere = {
  createdAt?: InputMaybe<Scalars['String']>;
};

export type DeviceConfigType = {
  __typename?: 'DeviceConfigType';
  departments: Array<DepartmentType>;
  deviceUsers: Array<DeviceUserType>;
  hardwareId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DeviceDto = {
  hardwareId: Scalars['String'];
  isNotificationMuted: Scalars['Boolean'];
  maintainers?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
};

export type DeviceList = {
  __typename?: 'DeviceList';
  list: Array<DeviceType>;
  totalCount: Scalars['Int'];
};

export type DeviceSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  hardwareId?: InputMaybe<Scalars['String']>;
  heartbeat?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  createdAt: Scalars['DateTime'];
  hardwareId: Scalars['String'];
  heartbeat?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isNotificationMuted: Scalars['Boolean'];
  /** Maintainer field for multi select input */
  maintainersForMultiSelect: Array<MultiSelectOptionType>;
  name: Scalars['String'];
};

export type DeviceUserList = {
  __typename?: 'DeviceUserList';
  list: Array<DeviceUserType>;
  totalCount: Scalars['Int'];
};

export type DeviceUserSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type DeviceUserType = {
  __typename?: 'DeviceUserType';
  createdAt: Scalars['DateTime'];
  departments: Array<DepartmentType>;
  firstName: Scalars['String'];
  hasBasicAccess: Scalars['Boolean'];
  hasDistributionAccess: Scalars['Boolean'];
  id: Scalars['Float'];
  identifier: Scalars['String'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  pin: Scalars['String'];
  tag: Scalars['String'];
};

export type DeviceUserWhere = {
  createdAt?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hasBasicAccess?: InputMaybe<Scalars['Boolean']>;
  hasDistributionAccess?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type DeviceWhere = {
  createdAt?: InputMaybe<Scalars['String']>;
};

export type EventDto = {
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentName?: InputMaybe<Scalars['String']>;
  deviceTimestamp: Scalars['Float'];
  event: Scalars['String'];
  lockerIndex?: InputMaybe<Scalars['Int']>;
  userBasicAccess: Scalars['Boolean'];
  userDistributionAccess: Scalars['Boolean'];
  userId: Scalars['Int'];
  userName: Scalars['String'];
  userTag: Scalars['String'];
};

export type EventListType = {
  __typename?: 'EventListType';
  list: Array<EventType>;
  totalCount: Scalars['Int'];
};

export type EventSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  deviceTimestamp?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type EventType = {
  __typename?: 'EventType';
  createdAt: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['Int']>;
  departmentName?: Maybe<Scalars['String']>;
  deviceHardwareId: Scalars['String'];
  deviceId: Scalars['Int'];
  deviceName: Scalars['String'];
  deviceTimestamp: Scalars['Float'];
  event: Scalars['String'];
  id: Scalars['Int'];
  lockerIndex?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  userBasicAccess: Scalars['Boolean'];
  userDistributionAccess: Scalars['Boolean'];
  userId: Scalars['Float'];
  userName: Scalars['String'];
  userTag: Scalars['String'];
};

export type EventWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  userTag?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordDto = {
  email: Scalars['String'];
};

export type InvitationTokenDto = {
  invitationToken: Scalars['String'];
};

export type InviteUserDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type LoginDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginType = {
  __typename?: 'LoginType';
  token: Scalars['String'];
  user: UserType;
};

export type MultiSelectOptionType = {
  __typename?: 'MultiSelectOptionType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Complete invited user's account */
  completeInvitation: SimpleStatusType;
  /** Create a new department */
  createDepartment: DepartmentType;
  /** Create a new device */
  createDevice: DeviceType;
  createDeviceEvent: EventType;
  /** Create a new device user */
  createDeviceUser: DeviceUserType;
  /** Delete a department by {{ id }} */
  deleteDepartment: DepartmentType;
  /** Delete device */
  deleteDevice: DeviceType;
  /** Delete device user */
  deleteDeviceUser: DeviceUserType;
  /** Delete device */
  deleteUser: UserType;
  /** Send a reset password email to the user */
  forgotPassword: SimpleStatusType;
  /** Invite new user */
  inviteUser: UserType;
  /** Login a user with email and password */
  login: LoginType;
  /** Reset the user's password via a password-reset token */
  resetPassword: SimpleStatusType;
  /** Update the department */
  updateDepartment: DepartmentType;
  /** Update device */
  updateDevice: DeviceType;
  /** Update device user's basic information */
  updateDeviceUser: DeviceUserType;
  /** Update device user's departments */
  updateDeviceUserDepartments: DeviceUserType;
  /** Update device user's pin */
  updateDeviceUserPin: DeviceUserType;
  /** Update device user's basic information */
  updateDeviceUserPrivileges: DeviceUserType;
  /** Update current user's password */
  updatePassword: UserType;
  /** Update user */
  updateUser: UserType;
};


export type MutationCompleteInvitationArgs = {
  body: CompleteInvitationDto;
};


export type MutationCreateDepartmentArgs = {
  body: DepartmentDto;
};


export type MutationCreateDeviceArgs = {
  body: CreateDeviceDto;
};


export type MutationCreateDeviceEventArgs = {
  body: EventDto;
};


export type MutationCreateDeviceUserArgs = {
  body: CreateDeviceUserDto;
};


export type MutationDeleteDepartmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDeviceUserArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Float'];
};


export type MutationForgotPasswordArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginArgs = {
  body: LoginDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationUpdateDepartmentArgs = {
  body: DepartmentDto;
  id: Scalars['Int'];
};


export type MutationUpdateDeviceArgs = {
  body: DeviceDto;
  id: Scalars['Int'];
};


export type MutationUpdateDeviceUserArgs = {
  body: UpdateDeviceUserInfoDto;
  id: Scalars['Int'];
};


export type MutationUpdateDeviceUserDepartmentsArgs = {
  body: UpdateDeviceUserDepartmentsDto;
  id: Scalars['Int'];
};


export type MutationUpdateDeviceUserPinArgs = {
  body: UpdateDeviceUserPinDto;
  id: Scalars['Int'];
};


export type MutationUpdateDeviceUserPrivilegesArgs = {
  body: UpdateDeviceUserPrivilegesDto;
  id: Scalars['Int'];
};


export type MutationUpdatePasswordArgs = {
  body: PasswordDto;
};


export type MutationUpdateUserArgs = {
  body: UserDto;
  id: Scalars['Float'];
};

export type PasswordDto = {
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Find a description by {{id}} */
  department: DepartmentType;
  /** Find all departments matched by a filter */
  departments: DepartmentList;
  /** Find a device by {{id}} */
  device: DeviceType;
  /** Find authorized device's configuration data. Device only operation and require device authorization. */
  deviceConfig: DeviceConfigType;
  /** Find a device user by {{id}} */
  deviceUser: DeviceUserType;
  /** Find all device user matched by a filter */
  deviceUsers: DeviceUserList;
  /** Find all devices matched by a filter */
  devices: DeviceList;
  events: EventListType;
  /** Find a device by {{id}} */
  user: UserType;
  /** Find all users matched by a filter */
  users: UserList;
  /** Validate user's invitation reset token */
  validateInvitationToken: ValidateInvitationTokenType;
  /** Validate user's password reset token */
  validateResetToken: SimpleStatusType;
  /** Get authorized user's data. */
  whoAmI: UserType;
};


export type QueryDepartmentArgs = {
  id: Scalars['Int'];
};


export type QueryDepartmentsArgs = {
  orderBy?: InputMaybe<Array<DepartmentSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DepartmentWhere>;
};


export type QueryDeviceArgs = {
  id: Scalars['Int'];
};


export type QueryDeviceUserArgs = {
  id: Scalars['Int'];
};


export type QueryDeviceUsersArgs = {
  orderBy?: InputMaybe<Array<DeviceUserSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceUserWhere>;
};


export type QueryDevicesArgs = {
  orderBy?: InputMaybe<Array<DeviceSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceWhere>;
};


export type QueryEventsArgs = {
  orderBy?: InputMaybe<Array<EventSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhere>;
};


export type QueryUserArgs = {
  id: Scalars['Float'];
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryValidateInvitationTokenArgs = {
  body: InvitationTokenDto;
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};

export type ResetPasswordDto = {
  password: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type SimpleStatusType = {
  __typename?: 'SimpleStatusType';
  status: Scalars['String'];
};

export type UpdateDeviceUserDepartmentsDto = {
  departments: Array<Scalars['Int']>;
};

export type UpdateDeviceUserInfoDto = {
  firstName: Scalars['String'];
  identifier: Scalars['String'];
  lastName: Scalars['String'];
  tag: Scalars['String'];
};

export type UpdateDeviceUserPinDto = {
  pin: Scalars['String'];
};

export type UpdateDeviceUserPrivilegesDto = {
  hasBasicAccess: Scalars['Boolean'];
  hasDistributionAccess: Scalars['Boolean'];
};

export type UserDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<UserType>;
  totalCount: Scalars['Int'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  protected: Scalars['Boolean'];
};

export type UserWhere = {
  createdAt?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type ValidateInvitationTokenType = {
  __typename?: 'ValidateInvitationTokenType';
  language: Scalars['String'];
  status: Scalars['String'];
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String'];
};

export type CreateDepartmentMutationVariables = Exact<{
  body: DepartmentDto;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment: { __typename?: 'DepartmentType', id: number } };

export type CreateDeviceMutationVariables = Exact<{
  body: CreateDeviceDto;
}>;


export type CreateDeviceMutation = { __typename?: 'Mutation', createDevice: { __typename?: 'DeviceType', id: number } };

export type CreateDeviceUserMutationVariables = Exact<{
  body: CreateDeviceUserDto;
}>;


export type CreateDeviceUserMutation = { __typename?: 'Mutation', createDeviceUser: { __typename?: 'DeviceUserType', id: number } };

export type DeleteDepartmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDepartmentMutation = { __typename?: 'Mutation', deleteDepartment: { __typename?: 'DepartmentType', id: number } };

export type DeleteDeviceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDeviceMutation = { __typename?: 'Mutation', deleteDevice: { __typename?: 'DeviceType', id: number } };

export type DeleteDeviceUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDeviceUserMutation = { __typename?: 'Mutation', deleteDeviceUser: { __typename?: 'DeviceUserType', id: number } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'UserType', id: number } };

export type DepartmentEditSceneQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DepartmentEditSceneQuery = { __typename?: 'Query', department: { __typename?: 'DepartmentType', name: string } };

export type DepartmentIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DepartmentSort> | DepartmentSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DepartmentWhere>;
}>;


export type DepartmentIndexSceneQuery = { __typename?: 'Query', departments: { __typename?: 'DepartmentList', totalCount: number, list: Array<{ __typename?: 'DepartmentType', id: number, name: string }> } };

export type DeviceEditSceneQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeviceEditSceneQuery = { __typename?: 'Query', device: { __typename?: 'DeviceType', hardwareId: string, name: string, isNotificationMuted: boolean, maintainers: Array<{ __typename?: 'MultiSelectOptionType', label: string, value: number }> } };

export type DeviceIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DeviceSort> | DeviceSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceWhere>;
}>;


export type DeviceIndexSceneQuery = { __typename?: 'Query', devices: { __typename?: 'DeviceList', totalCount: number, list: Array<{ __typename?: 'DeviceType', hardwareId: string, id: number, heartbeat?: any | null, name: string, createdAt: any }> } };

export type DeviceUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeviceUserQuery = { __typename?: 'Query', deviceUser: { __typename?: 'DeviceUserType', id: number, name: string, hasDistributionAccess: boolean, hasBasicAccess: boolean } };

export type DeviceUserDepartmentEditSceneQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeviceUserDepartmentEditSceneQuery = { __typename?: 'Query', deviceUser: { __typename?: 'DeviceUserType', departments: Array<{ __typename?: 'DepartmentType', id: number }> }, departments: { __typename?: 'DepartmentList', list: Array<{ __typename?: 'DepartmentType', id: number, name: string }> } };

export type DeviceUserIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DeviceUserSort> | DeviceUserSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceUserWhere>;
}>;


export type DeviceUserIndexSceneQuery = { __typename?: 'Query', deviceUsers: { __typename?: 'DeviceUserList', totalCount: number, list: Array<{ __typename?: 'DeviceUserType', id: number, name: string, tag: string, createdAt: any }> } };

export type DeviceUserInfoEditQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeviceUserInfoEditQuery = { __typename?: 'Query', deviceUser: { __typename?: 'DeviceUserType', firstName: string, lastName: string, tag: string, identifier: string } };

export type DeviceUserPrivilegesEditSceneQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeviceUserPrivilegesEditSceneQuery = { __typename?: 'Query', deviceUser: { __typename?: 'DeviceUserType', id: number, hasBasicAccess: boolean, hasDistributionAccess: boolean } };

export type EventIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<EventSort> | EventSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhere>;
}>;


export type EventIndexSceneQuery = { __typename?: 'Query', events: { __typename?: 'EventListType', totalCount: number, list: Array<{ __typename?: 'EventType', id: number, lockerIndex?: number | null, event: string, userId: number, userName: string, userDistributionAccess: boolean, userBasicAccess: boolean, userTag: string, departmentId?: number | null, departmentName?: string | null, deviceId: number, deviceName: string, deviceHardwareId: string, deviceTimestamp: number, createdAt: any, updatedAt: any }> } };

export type InviteUserMutationVariables = Exact<{
  body: InviteUserDto;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'UserType', id: number } };

export type UpdateDepartmentMutationVariables = Exact<{
  body: DepartmentDto;
  id: Scalars['Int'];
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'DepartmentType', id: number } };

export type UpdateDeviceMutationVariables = Exact<{
  body: DeviceDto;
  id: Scalars['Int'];
}>;


export type UpdateDeviceMutation = { __typename?: 'Mutation', updateDevice: { __typename?: 'DeviceType', id: number } };

export type UpdateDeviceUserMutationVariables = Exact<{
  body: UpdateDeviceUserInfoDto;
  id: Scalars['Int'];
}>;


export type UpdateDeviceUserMutation = { __typename?: 'Mutation', updateDeviceUser: { __typename?: 'DeviceUserType', id: number } };

export type UpdateDeviceUserDepartmentsMutationVariables = Exact<{
  body: UpdateDeviceUserDepartmentsDto;
  id: Scalars['Int'];
}>;


export type UpdateDeviceUserDepartmentsMutation = { __typename?: 'Mutation', updateDeviceUserDepartments: { __typename?: 'DeviceUserType', id: number } };

export type UpdateDeviceUserPinMutationVariables = Exact<{
  body: UpdateDeviceUserPinDto;
  id: Scalars['Int'];
}>;


export type UpdateDeviceUserPinMutation = { __typename?: 'Mutation', updateDeviceUserPin: { __typename?: 'DeviceUserType', id: number } };

export type UpdatePasswordMutationVariables = Exact<{
  body: PasswordDto;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'UserType', id: number } };

export type UpdateDeviceUserPrivilegesMutationVariables = Exact<{
  body: UpdateDeviceUserPrivilegesDto;
  id: Scalars['Int'];
}>;


export type UpdateDeviceUserPrivilegesMutation = { __typename?: 'Mutation', updateDeviceUserPrivileges: { __typename?: 'DeviceUserType', id: number } };

export type UpdateUserMutationVariables = Exact<{
  body: UserDto;
  id: Scalars['Float'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserType', id: number } };

export type UserEditSceneQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type UserEditSceneQuery = { __typename?: 'Query', user: { __typename?: 'UserType', email: string, firstName: string, lastName: string, protected: boolean } };

export type UserIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UserSort> | UserSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhere>;
}>;


export type UserIndexSceneQuery = { __typename?: 'Query', users: { __typename?: 'UserList', totalCount: number, list: Array<{ __typename?: 'UserType', email: string, firstName: string, id: number, lastName: string, createdAt: any, name: string }> } };

export type CompleteInvitationMutationVariables = Exact<{
  body: CompleteInvitationDto;
}>;


export type CompleteInvitationMutation = { __typename?: 'Mutation', completeInvitation: { __typename?: 'SimpleStatusType', status: string } };

export type ForgotPasswordMutationVariables = Exact<{
  body: ForgotPasswordDto;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'SimpleStatusType', status: string } };

export type LoginMutationVariables = Exact<{
  body: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginType', token: string } };

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordDto;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'SimpleStatusType', status: string } };

export type ValidateInvitationTokenQueryVariables = Exact<{
  body: InvitationTokenDto;
}>;


export type ValidateInvitationTokenQuery = { __typename?: 'Query', validateInvitationToken: { __typename?: 'ValidateInvitationTokenType', status: string, language: string } };

export type ValidateResetTokenQueryVariables = Exact<{
  body: ValidateResetTokenDto;
}>;


export type ValidateResetTokenQuery = { __typename?: 'Query', validateResetToken: { __typename?: 'SimpleStatusType', status: string } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', id: number, name: string } };


export const CreateDepartmentDocument = gql`
    mutation CreateDepartment($body: DepartmentDTO!) {
  createDepartment(body: $body) {
    id
  }
}
    `;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($body: CreateDeviceDTO!) {
  createDevice(body: $body) {
    id
  }
}
    `;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const CreateDeviceUserDocument = gql`
    mutation CreateDeviceUser($body: CreateDeviceUserDTO!) {
  createDeviceUser(body: $body) {
    id
  }
}
    `;
export type CreateDeviceUserMutationFn = Apollo.MutationFunction<CreateDeviceUserMutation, CreateDeviceUserMutationVariables>;

/**
 * __useCreateDeviceUserMutation__
 *
 * To run a mutation, you first call `useCreateDeviceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceUserMutation, { data, loading, error }] = useCreateDeviceUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDeviceUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceUserMutation, CreateDeviceUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceUserMutation, CreateDeviceUserMutationVariables>(CreateDeviceUserDocument, options);
      }
export type CreateDeviceUserMutationHookResult = ReturnType<typeof useCreateDeviceUserMutation>;
export type CreateDeviceUserMutationResult = Apollo.MutationResult<CreateDeviceUserMutation>;
export type CreateDeviceUserMutationOptions = Apollo.BaseMutationOptions<CreateDeviceUserMutation, CreateDeviceUserMutationVariables>;
export const DeleteDepartmentDocument = gql`
    mutation DeleteDepartment($id: Int!) {
  deleteDepartment(id: $id) {
    id
  }
}
    `;
export type DeleteDepartmentMutationFn = Apollo.MutationFunction<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;

/**
 * __useDeleteDepartmentMutation__
 *
 * To run a mutation, you first call `useDeleteDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepartmentMutation, { data, loading, error }] = useDeleteDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>(DeleteDepartmentDocument, options);
      }
export type DeleteDepartmentMutationHookResult = ReturnType<typeof useDeleteDepartmentMutation>;
export type DeleteDepartmentMutationResult = Apollo.MutationResult<DeleteDepartmentMutation>;
export type DeleteDepartmentMutationOptions = Apollo.BaseMutationOptions<DeleteDepartmentMutation, DeleteDepartmentMutationVariables>;
export const DeleteDeviceDocument = gql`
    mutation DeleteDevice($id: Int!) {
  deleteDevice(id: $id) {
    id
  }
}
    `;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<DeleteDeviceMutation, DeleteDeviceMutationVariables>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument, options);
      }
export type DeleteDeviceMutationHookResult = ReturnType<typeof useDeleteDeviceMutation>;
export type DeleteDeviceMutationResult = Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>;
export const DeleteDeviceUserDocument = gql`
    mutation DeleteDeviceUser($id: Int!) {
  deleteDeviceUser(id: $id) {
    id
  }
}
    `;
export type DeleteDeviceUserMutationFn = Apollo.MutationFunction<DeleteDeviceUserMutation, DeleteDeviceUserMutationVariables>;

/**
 * __useDeleteDeviceUserMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceUserMutation, { data, loading, error }] = useDeleteDeviceUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDeviceUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceUserMutation, DeleteDeviceUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceUserMutation, DeleteDeviceUserMutationVariables>(DeleteDeviceUserDocument, options);
      }
export type DeleteDeviceUserMutationHookResult = ReturnType<typeof useDeleteDeviceUserMutation>;
export type DeleteDeviceUserMutationResult = Apollo.MutationResult<DeleteDeviceUserMutation>;
export type DeleteDeviceUserMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceUserMutation, DeleteDeviceUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Float!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DepartmentEditSceneDocument = gql`
    query DepartmentEditScene($id: Int!) {
  department(id: $id) {
    name
  }
}
    `;

/**
 * __useDepartmentEditSceneQuery__
 *
 * To run a query within a React component, call `useDepartmentEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDepartmentEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DepartmentEditSceneQuery, DepartmentEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentEditSceneQuery, DepartmentEditSceneQueryVariables>(DepartmentEditSceneDocument, options);
      }
export function useDepartmentEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentEditSceneQuery, DepartmentEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentEditSceneQuery, DepartmentEditSceneQueryVariables>(DepartmentEditSceneDocument, options);
        }
export type DepartmentEditSceneQueryHookResult = ReturnType<typeof useDepartmentEditSceneQuery>;
export type DepartmentEditSceneLazyQueryHookResult = ReturnType<typeof useDepartmentEditSceneLazyQuery>;
export type DepartmentEditSceneQueryResult = Apollo.QueryResult<DepartmentEditSceneQuery, DepartmentEditSceneQueryVariables>;
export const DepartmentIndexSceneDocument = gql`
    query DepartmentIndexScene($orderBy: [DepartmentSort!], $skip: Int, $take: Int, $where: DepartmentWhere) {
  departments(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      name
    }
    totalCount
  }
}
    `;

/**
 * __useDepartmentIndexSceneQuery__
 *
 * To run a query within a React component, call `useDepartmentIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDepartmentIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentIndexSceneQuery, DepartmentIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentIndexSceneQuery, DepartmentIndexSceneQueryVariables>(DepartmentIndexSceneDocument, options);
      }
export function useDepartmentIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentIndexSceneQuery, DepartmentIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentIndexSceneQuery, DepartmentIndexSceneQueryVariables>(DepartmentIndexSceneDocument, options);
        }
export type DepartmentIndexSceneQueryHookResult = ReturnType<typeof useDepartmentIndexSceneQuery>;
export type DepartmentIndexSceneLazyQueryHookResult = ReturnType<typeof useDepartmentIndexSceneLazyQuery>;
export type DepartmentIndexSceneQueryResult = Apollo.QueryResult<DepartmentIndexSceneQuery, DepartmentIndexSceneQueryVariables>;
export const DeviceEditSceneDocument = gql`
    query DeviceEditScene($id: Int!) {
  device(id: $id) {
    hardwareId
    name
    isNotificationMuted
    maintainers: maintainersForMultiSelect {
      label
      value
    }
  }
}
    `;

/**
 * __useDeviceEditSceneQuery__
 *
 * To run a query within a React component, call `useDeviceEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DeviceEditSceneQuery, DeviceEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceEditSceneQuery, DeviceEditSceneQueryVariables>(DeviceEditSceneDocument, options);
      }
export function useDeviceEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceEditSceneQuery, DeviceEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceEditSceneQuery, DeviceEditSceneQueryVariables>(DeviceEditSceneDocument, options);
        }
export type DeviceEditSceneQueryHookResult = ReturnType<typeof useDeviceEditSceneQuery>;
export type DeviceEditSceneLazyQueryHookResult = ReturnType<typeof useDeviceEditSceneLazyQuery>;
export type DeviceEditSceneQueryResult = Apollo.QueryResult<DeviceEditSceneQuery, DeviceEditSceneQueryVariables>;
export const DeviceIndexSceneDocument = gql`
    query DeviceIndexScene($orderBy: [DeviceSort!], $skip: Int, $take: Int, $where: DeviceWhere) {
  devices(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      hardwareId
      id
      heartbeat
      name
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useDeviceIndexSceneQuery__
 *
 * To run a query within a React component, call `useDeviceIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeviceIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<DeviceIndexSceneQuery, DeviceIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceIndexSceneQuery, DeviceIndexSceneQueryVariables>(DeviceIndexSceneDocument, options);
      }
export function useDeviceIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceIndexSceneQuery, DeviceIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceIndexSceneQuery, DeviceIndexSceneQueryVariables>(DeviceIndexSceneDocument, options);
        }
export type DeviceIndexSceneQueryHookResult = ReturnType<typeof useDeviceIndexSceneQuery>;
export type DeviceIndexSceneLazyQueryHookResult = ReturnType<typeof useDeviceIndexSceneLazyQuery>;
export type DeviceIndexSceneQueryResult = Apollo.QueryResult<DeviceIndexSceneQuery, DeviceIndexSceneQueryVariables>;
export const DeviceUserDocument = gql`
    query DeviceUser($id: Int!) {
  deviceUser(id: $id) {
    id
    name
    hasDistributionAccess
    hasBasicAccess
  }
}
    `;

/**
 * __useDeviceUserQuery__
 *
 * To run a query within a React component, call `useDeviceUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceUserQuery(baseOptions: Apollo.QueryHookOptions<DeviceUserQuery, DeviceUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserQuery, DeviceUserQueryVariables>(DeviceUserDocument, options);
      }
export function useDeviceUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserQuery, DeviceUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserQuery, DeviceUserQueryVariables>(DeviceUserDocument, options);
        }
export type DeviceUserQueryHookResult = ReturnType<typeof useDeviceUserQuery>;
export type DeviceUserLazyQueryHookResult = ReturnType<typeof useDeviceUserLazyQuery>;
export type DeviceUserQueryResult = Apollo.QueryResult<DeviceUserQuery, DeviceUserQueryVariables>;
export const DeviceUserDepartmentEditSceneDocument = gql`
    query DeviceUserDepartmentEditScene($id: Int!) {
  deviceUser(id: $id) {
    departments {
      id
    }
  }
  departments {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useDeviceUserDepartmentEditSceneQuery__
 *
 * To run a query within a React component, call `useDeviceUserDepartmentEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserDepartmentEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserDepartmentEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceUserDepartmentEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DeviceUserDepartmentEditSceneQuery, DeviceUserDepartmentEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserDepartmentEditSceneQuery, DeviceUserDepartmentEditSceneQueryVariables>(DeviceUserDepartmentEditSceneDocument, options);
      }
export function useDeviceUserDepartmentEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserDepartmentEditSceneQuery, DeviceUserDepartmentEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserDepartmentEditSceneQuery, DeviceUserDepartmentEditSceneQueryVariables>(DeviceUserDepartmentEditSceneDocument, options);
        }
export type DeviceUserDepartmentEditSceneQueryHookResult = ReturnType<typeof useDeviceUserDepartmentEditSceneQuery>;
export type DeviceUserDepartmentEditSceneLazyQueryHookResult = ReturnType<typeof useDeviceUserDepartmentEditSceneLazyQuery>;
export type DeviceUserDepartmentEditSceneQueryResult = Apollo.QueryResult<DeviceUserDepartmentEditSceneQuery, DeviceUserDepartmentEditSceneQueryVariables>;
export const DeviceUserIndexSceneDocument = gql`
    query DeviceUserIndexScene($orderBy: [DeviceUserSort!], $skip: Int, $take: Int, $where: DeviceUserWhere) {
  deviceUsers(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      name
      tag
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useDeviceUserIndexSceneQuery__
 *
 * To run a query within a React component, call `useDeviceUserIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeviceUserIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<DeviceUserIndexSceneQuery, DeviceUserIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserIndexSceneQuery, DeviceUserIndexSceneQueryVariables>(DeviceUserIndexSceneDocument, options);
      }
export function useDeviceUserIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserIndexSceneQuery, DeviceUserIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserIndexSceneQuery, DeviceUserIndexSceneQueryVariables>(DeviceUserIndexSceneDocument, options);
        }
export type DeviceUserIndexSceneQueryHookResult = ReturnType<typeof useDeviceUserIndexSceneQuery>;
export type DeviceUserIndexSceneLazyQueryHookResult = ReturnType<typeof useDeviceUserIndexSceneLazyQuery>;
export type DeviceUserIndexSceneQueryResult = Apollo.QueryResult<DeviceUserIndexSceneQuery, DeviceUserIndexSceneQueryVariables>;
export const DeviceUserInfoEditDocument = gql`
    query DeviceUserInfoEdit($id: Int!) {
  deviceUser(id: $id) {
    firstName
    lastName
    tag
    identifier
  }
}
    `;

/**
 * __useDeviceUserInfoEditQuery__
 *
 * To run a query within a React component, call `useDeviceUserInfoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserInfoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserInfoEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceUserInfoEditQuery(baseOptions: Apollo.QueryHookOptions<DeviceUserInfoEditQuery, DeviceUserInfoEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserInfoEditQuery, DeviceUserInfoEditQueryVariables>(DeviceUserInfoEditDocument, options);
      }
export function useDeviceUserInfoEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserInfoEditQuery, DeviceUserInfoEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserInfoEditQuery, DeviceUserInfoEditQueryVariables>(DeviceUserInfoEditDocument, options);
        }
export type DeviceUserInfoEditQueryHookResult = ReturnType<typeof useDeviceUserInfoEditQuery>;
export type DeviceUserInfoEditLazyQueryHookResult = ReturnType<typeof useDeviceUserInfoEditLazyQuery>;
export type DeviceUserInfoEditQueryResult = Apollo.QueryResult<DeviceUserInfoEditQuery, DeviceUserInfoEditQueryVariables>;
export const DeviceUserPrivilegesEditSceneDocument = gql`
    query DeviceUserPrivilegesEditScene($id: Int!) {
  deviceUser(id: $id) {
    id
    hasBasicAccess
    hasDistributionAccess
  }
}
    `;

/**
 * __useDeviceUserPrivilegesEditSceneQuery__
 *
 * To run a query within a React component, call `useDeviceUserPrivilegesEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUserPrivilegesEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUserPrivilegesEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceUserPrivilegesEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DeviceUserPrivilegesEditSceneQuery, DeviceUserPrivilegesEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUserPrivilegesEditSceneQuery, DeviceUserPrivilegesEditSceneQueryVariables>(DeviceUserPrivilegesEditSceneDocument, options);
      }
export function useDeviceUserPrivilegesEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUserPrivilegesEditSceneQuery, DeviceUserPrivilegesEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUserPrivilegesEditSceneQuery, DeviceUserPrivilegesEditSceneQueryVariables>(DeviceUserPrivilegesEditSceneDocument, options);
        }
export type DeviceUserPrivilegesEditSceneQueryHookResult = ReturnType<typeof useDeviceUserPrivilegesEditSceneQuery>;
export type DeviceUserPrivilegesEditSceneLazyQueryHookResult = ReturnType<typeof useDeviceUserPrivilegesEditSceneLazyQuery>;
export type DeviceUserPrivilegesEditSceneQueryResult = Apollo.QueryResult<DeviceUserPrivilegesEditSceneQuery, DeviceUserPrivilegesEditSceneQueryVariables>;
export const EventIndexSceneDocument = gql`
    query EventIndexScene($orderBy: [EventSort!], $skip: Int, $take: Int, $where: EventWhere) {
  events(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      lockerIndex
      event
      userId
      userName
      userDistributionAccess
      userBasicAccess
      userTag
      departmentId
      departmentName
      deviceId
      deviceName
      deviceHardwareId
      deviceTimestamp
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useEventIndexSceneQuery__
 *
 * To run a query within a React component, call `useEventIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<EventIndexSceneQuery, EventIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventIndexSceneQuery, EventIndexSceneQueryVariables>(EventIndexSceneDocument, options);
      }
export function useEventIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventIndexSceneQuery, EventIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventIndexSceneQuery, EventIndexSceneQueryVariables>(EventIndexSceneDocument, options);
        }
export type EventIndexSceneQueryHookResult = ReturnType<typeof useEventIndexSceneQuery>;
export type EventIndexSceneLazyQueryHookResult = ReturnType<typeof useEventIndexSceneLazyQuery>;
export type EventIndexSceneQueryResult = Apollo.QueryResult<EventIndexSceneQuery, EventIndexSceneQueryVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($body: InviteUserDTO!) {
  inviteUser(body: $body) {
    id
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation UpdateDepartment($body: DepartmentDTO!, $id: Int!) {
  updateDepartment(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($body: DeviceDTO!, $id: Int!) {
  updateDevice(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const UpdateDeviceUserDocument = gql`
    mutation UpdateDeviceUser($body: UpdateDeviceUserInfoDTO!, $id: Int!) {
  updateDeviceUser(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDeviceUserMutationFn = Apollo.MutationFunction<UpdateDeviceUserMutation, UpdateDeviceUserMutationVariables>;

/**
 * __useUpdateDeviceUserMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUserMutation, { data, loading, error }] = useUpdateDeviceUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeviceUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUserMutation, UpdateDeviceUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUserMutation, UpdateDeviceUserMutationVariables>(UpdateDeviceUserDocument, options);
      }
export type UpdateDeviceUserMutationHookResult = ReturnType<typeof useUpdateDeviceUserMutation>;
export type UpdateDeviceUserMutationResult = Apollo.MutationResult<UpdateDeviceUserMutation>;
export type UpdateDeviceUserMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUserMutation, UpdateDeviceUserMutationVariables>;
export const UpdateDeviceUserDepartmentsDocument = gql`
    mutation UpdateDeviceUserDepartments($body: UpdateDeviceUserDepartmentsDTO!, $id: Int!) {
  updateDeviceUserDepartments(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDeviceUserDepartmentsMutationFn = Apollo.MutationFunction<UpdateDeviceUserDepartmentsMutation, UpdateDeviceUserDepartmentsMutationVariables>;

/**
 * __useUpdateDeviceUserDepartmentsMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUserDepartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUserDepartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUserDepartmentsMutation, { data, loading, error }] = useUpdateDeviceUserDepartmentsMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeviceUserDepartmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUserDepartmentsMutation, UpdateDeviceUserDepartmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUserDepartmentsMutation, UpdateDeviceUserDepartmentsMutationVariables>(UpdateDeviceUserDepartmentsDocument, options);
      }
export type UpdateDeviceUserDepartmentsMutationHookResult = ReturnType<typeof useUpdateDeviceUserDepartmentsMutation>;
export type UpdateDeviceUserDepartmentsMutationResult = Apollo.MutationResult<UpdateDeviceUserDepartmentsMutation>;
export type UpdateDeviceUserDepartmentsMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUserDepartmentsMutation, UpdateDeviceUserDepartmentsMutationVariables>;
export const UpdateDeviceUserPinDocument = gql`
    mutation UpdateDeviceUserPin($body: UpdateDeviceUserPinDTO!, $id: Int!) {
  updateDeviceUserPin(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDeviceUserPinMutationFn = Apollo.MutationFunction<UpdateDeviceUserPinMutation, UpdateDeviceUserPinMutationVariables>;

/**
 * __useUpdateDeviceUserPinMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUserPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUserPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUserPinMutation, { data, loading, error }] = useUpdateDeviceUserPinMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeviceUserPinMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUserPinMutation, UpdateDeviceUserPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUserPinMutation, UpdateDeviceUserPinMutationVariables>(UpdateDeviceUserPinDocument, options);
      }
export type UpdateDeviceUserPinMutationHookResult = ReturnType<typeof useUpdateDeviceUserPinMutation>;
export type UpdateDeviceUserPinMutationResult = Apollo.MutationResult<UpdateDeviceUserPinMutation>;
export type UpdateDeviceUserPinMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUserPinMutation, UpdateDeviceUserPinMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($body: PasswordDTO!) {
  updatePassword(body: $body) {
    id
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateDeviceUserPrivilegesDocument = gql`
    mutation UpdateDeviceUserPrivileges($body: UpdateDeviceUserPrivilegesDTO!, $id: Int!) {
  updateDeviceUserPrivileges(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDeviceUserPrivilegesMutationFn = Apollo.MutationFunction<UpdateDeviceUserPrivilegesMutation, UpdateDeviceUserPrivilegesMutationVariables>;

/**
 * __useUpdateDeviceUserPrivilegesMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUserPrivilegesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUserPrivilegesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUserPrivilegesMutation, { data, loading, error }] = useUpdateDeviceUserPrivilegesMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDeviceUserPrivilegesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUserPrivilegesMutation, UpdateDeviceUserPrivilegesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUserPrivilegesMutation, UpdateDeviceUserPrivilegesMutationVariables>(UpdateDeviceUserPrivilegesDocument, options);
      }
export type UpdateDeviceUserPrivilegesMutationHookResult = ReturnType<typeof useUpdateDeviceUserPrivilegesMutation>;
export type UpdateDeviceUserPrivilegesMutationResult = Apollo.MutationResult<UpdateDeviceUserPrivilegesMutation>;
export type UpdateDeviceUserPrivilegesMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUserPrivilegesMutation, UpdateDeviceUserPrivilegesMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($body: UserDTO!, $id: Float!) {
  updateUser(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserEditSceneDocument = gql`
    query UserEditScene($id: Float!) {
  user(id: $id) {
    email
    firstName
    lastName
    protected
  }
}
    `;

/**
 * __useUserEditSceneQuery__
 *
 * To run a query within a React component, call `useUserEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEditSceneQuery(baseOptions: Apollo.QueryHookOptions<UserEditSceneQuery, UserEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditSceneQuery, UserEditSceneQueryVariables>(UserEditSceneDocument, options);
      }
export function useUserEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditSceneQuery, UserEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditSceneQuery, UserEditSceneQueryVariables>(UserEditSceneDocument, options);
        }
export type UserEditSceneQueryHookResult = ReturnType<typeof useUserEditSceneQuery>;
export type UserEditSceneLazyQueryHookResult = ReturnType<typeof useUserEditSceneLazyQuery>;
export type UserEditSceneQueryResult = Apollo.QueryResult<UserEditSceneQuery, UserEditSceneQueryVariables>;
export const UserIndexSceneDocument = gql`
    query UserIndexScene($orderBy: [UserSort!], $skip: Int, $take: Int, $where: UserWhere) {
  users(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      email
      firstName
      id
      lastName
      createdAt
      name
    }
    totalCount
  }
}
    `;

/**
 * __useUserIndexSceneQuery__
 *
 * To run a query within a React component, call `useUserIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
      }
export function useUserIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
        }
export type UserIndexSceneQueryHookResult = ReturnType<typeof useUserIndexSceneQuery>;
export type UserIndexSceneLazyQueryHookResult = ReturnType<typeof useUserIndexSceneLazyQuery>;
export type UserIndexSceneQueryResult = Apollo.QueryResult<UserIndexSceneQuery, UserIndexSceneQueryVariables>;
export const CompleteInvitationDocument = gql`
    mutation CompleteInvitation($body: CompleteInvitationDTO!) {
  completeInvitation(body: $body) {
    status
  }
}
    `;
export type CompleteInvitationMutationFn = Apollo.MutationFunction<CompleteInvitationMutation, CompleteInvitationMutationVariables>;

/**
 * __useCompleteInvitationMutation__
 *
 * To run a mutation, you first call `useCompleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInvitationMutation, { data, loading, error }] = useCompleteInvitationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteInvitationMutation, CompleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteInvitationMutation, CompleteInvitationMutationVariables>(CompleteInvitationDocument, options);
      }
export type CompleteInvitationMutationHookResult = ReturnType<typeof useCompleteInvitationMutation>;
export type CompleteInvitationMutationResult = Apollo.MutationResult<CompleteInvitationMutation>;
export type CompleteInvitationMutationOptions = Apollo.BaseMutationOptions<CompleteInvitationMutation, CompleteInvitationMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($body: ForgotPasswordDto!) {
  forgotPassword(body: $body) {
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($body: LoginDto!) {
  login(body: $body) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($body: ResetPasswordDto!) {
  resetPassword(body: $body) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ValidateInvitationTokenDocument = gql`
    query ValidateInvitationToken($body: InvitationTokenDto!) {
  validateInvitationToken(body: $body) {
    status
    language
  }
}
    `;

/**
 * __useValidateInvitationTokenQuery__
 *
 * To run a query within a React component, call `useValidateInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateInvitationTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateInvitationTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>(ValidateInvitationTokenDocument, options);
      }
export function useValidateInvitationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>(ValidateInvitationTokenDocument, options);
        }
export type ValidateInvitationTokenQueryHookResult = ReturnType<typeof useValidateInvitationTokenQuery>;
export type ValidateInvitationTokenLazyQueryHookResult = ReturnType<typeof useValidateInvitationTokenLazyQuery>;
export type ValidateInvitationTokenQueryResult = Apollo.QueryResult<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>;
export const ValidateResetTokenDocument = gql`
    query ValidateResetToken($body: ValidateResetTokenDto!) {
  validateResetToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    name
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;