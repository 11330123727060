import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TableColumn } from '../components/table/TableColumn';
import { dateFormat } from '../config/date-format';

export const useDeviceColumn = () => {
  const { t } = useTranslation('Device');

  const id: TableColumn = {
    text: t('id'),
    field: 'id',
    sort: true,
  };

  const hardwareId: TableColumn = {
    text: t('hardwareId'),
    field: 'hardwareId',
    sort: true,
  };

  const name: TableColumn = {
    text: t('name'),
    field: 'name',
    sort: true,
  };

  // Yhteysaika
  const heartbeat: TableColumn = {
    text: t('heartbeat'),
    field: 'heartbeat',
    formatter: (data) => {
      if (!data.heartbeat) return '-';
      return moment(data.heartbeat).format(dateFormat);
    },
    sort: true,
  };

  const createdAt: TableColumn = {
    text: t('Common:createdAt'),
    field: 'createdAt',
    formatter: (data) => {
      if (!data.createdAt) return '-';
      return moment(data.createdAt).format(dateFormat);
    },
    sort: true,
  };

  return [id, name, hardwareId, heartbeat, createdAt];
};
