import { useContext } from 'react';
import { useOrderBy } from '../../components/table/use-order-by';
import { usePerPage } from '../../components/table/use-per-page';
import { useSkip } from '../../components/table/use-skip';
import { EventFilterContext } from '../../contexts/EventFilterContext';
import { EventIndexSceneQueryVariables } from '../../generated/graphql';

export const useEventIndexSearchParams = (): EventIndexSceneQueryVariables => {
  const skip = useSkip();
  const take = usePerPage();
  const [orderBy] = useOrderBy();
  const [filter] = useContext(EventFilterContext);

  return {
    skip,
    take,
    where: filter,
    orderBy,
  };
};
