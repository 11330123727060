import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const TBodyTR: FC<Props> = ({ children, onClick }) => {
  return (
    <tr className="even:bg-gray-50 cursor-pointer hover:bg-primary-50" onClick={onClick}>
      {children}
    </tr>
  );
};
