import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDepartmentColumns } from '../../columns/use-department.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Pagination } from '../../components/table/Pagination';
import { TBody } from '../../components/table/TBody';
import { TBodyTD } from '../../components/table/TBodyTD';
import { TBodyTR } from '../../components/table/TBodyTR';
import { THead } from '../../components/table/THead';
import { THeadTD } from '../../components/table/THeadTD';
import { THeadTR } from '../../components/table/THeadTR';
import { Table } from '../../components/table/Table';
import { TableStatus } from '../../components/table/TableStatus';
import { getRoute } from '../../config/routes.config';
import { useDepartmentIndexSceneQuery } from '../../generated/graphql';
import { useDepartmentIndexSearchParams } from '../../hooks/search-params/use-department-index-search-params';

export const DepartmentIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DepartmentIndexScene');
  const navigate = useNavigate();

  const columns = useDepartmentColumns();
  const variables = useDepartmentIndexSearchParams();
  const { data, loading } = useDepartmentIndexSceneQuery({
    variables,
  });

  const handleOnTRClick = (id: number) => navigate(getRoute('DEPARTMENT_EDIT', { id }));

  return (
    <Card>
      <ButtonLink className="float-right" to={getRoute('DEPARTMENT_CREATE')}>
        {t('create')}
      </ButtonLink>
      <CardTitle>{t('title')}</CardTitle>
      <Table>
        <THead>
          <THeadTR>
            {columns.map((column) => (
              <THeadTD column={column} key={column.field} />
            ))}
          </THeadTR>
        </THead>
        <TBody>
          {data?.departments.list.map((department) => (
            <TBodyTR key={department.id} onClick={() => handleOnTRClick(department.id)}>
              {columns.map((column) => (
                <TBodyTD key={column.field} column={column} item={department} />
              ))}
            </TBodyTR>
          ))}
        </TBody>
      </Table>
      <TableStatus data={data?.departments.list} loading={loading}>
        {t('emptyText')}
      </TableStatus>
      <hr />
      <Pagination total={data?.departments.totalCount} />
    </Card>
  );
};
