import { useSearchParams } from 'react-router-dom';

export const usePage = (): number => {
  const [searchParams] = useSearchParams();

  if (searchParams.has('page')) {
    const pageString = searchParams.get('page');

    if (pageString === null) {
      return 1;
    }

    try {
      const page = parseInt(pageString, 10);

      if (page < 1) {
        return 1;
      }

      return page;
    } catch {
      return 1;
    }
  }

  return 1;
};
