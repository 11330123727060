import { Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Page } from '../../components/Page';
import { getRoute } from '../../config/routes.config';
import { DeviceUserFormBody, deviceUserForm } from '../../forms/device-user.form';
import { CreateDeviceUserMutation, useCreateDeviceUserMutation } from '../../generated/graphql';
import { useOnComplete } from '../../hooks/use-on-complete';
import { Button } from '../../releox-engine/react/buttons/Button';

export const DeviceUserCreateScene = (): JSX.Element => {
  const [goDepartments, setGoDepartments] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('DeviceUserCreateScene');
  const onComplete = useOnComplete({
    callback: (d: CreateDeviceUserMutation) => {
      if (d.createDeviceUser) {
        if (goDepartments) {
          const url = getRoute('DEVICE_USER_EDIT', {
            id: d.createDeviceUser.id,
            page: 'department',
          });
          navigate(url);
        } else {
          const url = getRoute('DEVICE_USER_INDEX');
          navigate(url);
        }
      }
    },
  });
  const [createDeviceUser, { loading }] = useCreateDeviceUserMutation({
    onCompleted: onComplete,
  });

  const _handleSubmit = useCallback(
    (body: DeviceUserFormBody) => {
      createDeviceUser({
        variables: {
          body: _.omit(body, 'pinConfirm'),
        },
      });
    },
    [createDeviceUser]
  );

  return (
    <Page title={t('title')}>
      <FormLayout>
        <CardTitle>{t('title')}</CardTitle>
        <Formik
          initialValues={deviceUserForm.meta.initValues}
          validationSchema={deviceUserForm.meta.validateSchema}
          onSubmit={_handleSubmit}
        >
          {({ handleSubmit, values }) => (
            <div className="space-y-5">
              <deviceUserForm.Element />
              <div className="flex justify-between">
                <ButtonLink to={getRoute('DEVICE_USER_INDEX')}>{t('Common:back')}</ButtonLink>

                <div>
                  <Button
                    type="button"
                    onClick={() => {
                      setGoDepartments(true);
                      handleSubmit();
                    }}
                    className="btn btn-primary mr-3"
                    loading={loading}
                    disabled={!values.hasBasicAccess}
                  >
                    {t('saveAndMoveToDepartments')}
                  </Button>

                  <Button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary"
                    loading={loading}
                  >
                    {t('Common:save')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </FormLayout>
    </Page>
  );
};
