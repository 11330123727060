import { LightBulbIcon } from '@heroicons/react/outline';
import { FC, Fragment, ReactNode } from 'react';
import { Loading } from '../Loading';

type Props = {
  loading: boolean;
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
};

export const TableStatus: FC<Props> = ({ loading, data, children }) => {
  if (loading) {
    return <Loading />;
  }

  if (data?.length) {
    return <Fragment />;
  }

  return (
    <div className="my-16 text-center">
      <LightBulbIcon height={30} className="mx-auto text-primary mb-2" />
      <p className="text-center text-gray-500 text-sm">{children}</p>
    </div>
  );
};
