import axios from 'axios';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEventColumn } from '../../columns/use-event.column';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { EventFilter } from '../../components/filters/EventFilter';
import { Pagination } from '../../components/table/Pagination';
import { TBody } from '../../components/table/TBody';
import { TBodyTD } from '../../components/table/TBodyTD';
import { TBodyTR } from '../../components/table/TBodyTR';
import { THead } from '../../components/table/THead';
import { THeadTD } from '../../components/table/THeadTD';
import { THeadTR } from '../../components/table/THeadTR';
import { Table } from '../../components/table/Table';
import { TableStatus } from '../../components/table/TableStatus';
import { useEventIndexSceneQuery } from '../../generated/graphql';
import { useEventIndexSearchParams } from '../../hooks/search-params/use-event-index-search-params';
import { Button } from '../../releox-engine/react/buttons/Button';
import { saveStringAsFile } from '../../utils/save-string-as-file';

export const EventIndexScene = (): JSX.Element => {
  const { t } = useTranslation('EventIndexScene');
  const columns = useEventColumn();
  const variables = useEventIndexSearchParams();
  const { data, loading } = useEventIndexSceneQuery({
    variables,
  });

  const handleExport = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const { data: csvData } = await axios.get(`${process.env.REACT_APP_API_URL}/export-system`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });

      saveStringAsFile('data.csv', csvData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(_.get(e, 'response.data.message'));
    }
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <EventFilter />
      </div>
      <div className="col-span-3">
        <Card>
          <div className="float-right">
            <Button type="button" onClick={handleExport}>
              {t('export')}
            </Button>
          </div>
          <CardTitle>{t('title')}</CardTitle>
          <Table>
            <THead>
              <THeadTR>
                {columns.map((column) => (
                  <THeadTD key={column.text} column={column} />
                ))}
              </THeadTR>
            </THead>
            <TBody>
              {data?.events.list.map((event) => (
                <TBodyTR key={event.id}>
                  {columns.map((column) => (
                    <TBodyTD key={column.text} column={column} item={event} />
                  ))}
                </TBodyTR>
              ))}
            </TBody>
          </Table>
          <TableStatus data={data?.events.list} loading={loading}>
            {t('emptyText')}
          </TableStatus>
          <hr />
          <Pagination total={data?.events.totalCount} />
        </Card>
      </div>
    </div>
  );
};
