import { useTranslation } from 'react-i18next';
import { TableColumn } from '../components/table/TableColumn';

export const useUserColumns = () => {
  const { t } = useTranslation('User');

  const name: TableColumn = {
    field: 'name',
    text: t('name'),
  };

  const email: TableColumn = {
    field: 'email',
    text: t('email'),
    sort: true,
  };

  const createdAt: TableColumn = {
    field: 'createdAt',
    text: t('Common:createdAt'),
    sort: true,
  };

  return [name, email, createdAt];
};
