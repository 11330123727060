export const PaginationTranslation = {
  fi: {
    Pagination: {
      page: 'Sivu',
      result: 'tulokset',
    },
  },
  en: {
    Pagination: {
      page: 'Page',
      result: 'result',
    },
  },
};
