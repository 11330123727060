import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeviceColumn } from '../../columns/use-device.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Pagination } from '../../components/table/Pagination';
import { TBody } from '../../components/table/TBody';
import { TBodyTD } from '../../components/table/TBodyTD';
import { TBodyTR } from '../../components/table/TBodyTR';
import { THead } from '../../components/table/THead';
import { THeadTD } from '../../components/table/THeadTD';
import { THeadTR } from '../../components/table/THeadTR';
import { Table } from '../../components/table/Table';
import { TableStatus } from '../../components/table/TableStatus';
import { getRoute } from '../../config/routes.config';
import { useDeviceIndexSceneQuery } from '../../generated/graphql';
import { useDeviceIndexSearchParams } from '../../hooks/search-params/use-device-index-search-params';

export const DeviceIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceIndexScene');
  const columns = useDeviceColumn();
  const variables = useDeviceIndexSearchParams();
  const { data, loading } = useDeviceIndexSceneQuery({
    variables,
  });
  const navigate = useNavigate();

  const handleOnClick = (id: number) => navigate(getRoute('DEVICE_EDIT', { id }));

  return (
    <Card>
      <ButtonLink to={getRoute('DEVICE_CREATE')} className="float-right">
        {t('create')}
      </ButtonLink>
      <CardTitle>{t('title')}</CardTitle>
      <Table>
        <THead>
          <THeadTR>
            {columns.map((column) => (
              <THeadTD key={column.text} column={column} />
            ))}
          </THeadTR>
        </THead>
        <TBody>
          {data?.devices.list.map((device) => (
            <TBodyTR key={device.id} onClick={() => handleOnClick(device.id)}>
              {columns.map((column) => (
                <TBodyTD key={column.text} column={column} item={device} />
              ))}
            </TBodyTR>
          ))}
        </TBody>
      </Table>
      <TableStatus data={data?.devices.list} loading={loading}>
        {t('emptyText')}
      </TableStatus>
      <hr />
      <Pagination total={data?.devices.totalCount} />
    </Card>
  );
};
