import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserColumns } from '../../columns/use-user.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { UserFilter } from '../../components/filters/UserFilter';
import { Pagination } from '../../components/table/Pagination';
import { TBody } from '../../components/table/TBody';
import { TBodyTD } from '../../components/table/TBodyTD';
import { TBodyTR } from '../../components/table/TBodyTR';
import { THead } from '../../components/table/THead';
import { THeadTD } from '../../components/table/THeadTD';
import { THeadTR } from '../../components/table/THeadTR';
import { Table } from '../../components/table/Table';
import { TableStatus } from '../../components/table/TableStatus';
import { getRoute } from '../../config/routes.config';
import { useUserIndexSceneQuery } from '../../generated/graphql';
import { useUserIndexSearchParams } from '../../hooks/search-params/use-user-index-search-params';

export const UserIndexScene = (): JSX.Element => {
  const { t } = useTranslation('UserIndexScene');
  const navigate = useNavigate();
  const columns = useUserColumns();
  const variables = useUserIndexSearchParams();
  const { data, loading } = useUserIndexSceneQuery({
    variables,
  });

  const handleOnClick = (id: number) => navigate(getRoute('USER_EDIT', { id }));

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <UserFilter />
      </div>
      <div className="col-span-3">
        <Card>
          <ButtonLink className="float-right" to={getRoute('USER_INVITE')}>
            {t('create')}
          </ButtonLink>
          <CardTitle>{t('title')}</CardTitle>
          <Table>
            <THead>
              <THeadTR>
                {columns.map((column) => (
                  <THeadTD key={column.field} column={column} />
                ))}
              </THeadTR>
            </THead>
            <TBody>
              {data?.users.list.map((user) => (
                <TBodyTR key={user.id} onClick={() => handleOnClick(user.id)}>
                  {columns.map((column) => (
                    <TBodyTD key={column.field} column={column} item={user} />
                  ))}
                </TBodyTR>
              ))}
            </TBody>
          </Table>
          <TableStatus loading={loading} data={data?.users.list}>
            {t('emptyText')}
          </TableStatus>
          <hr />
          <Pagination total={data?.users.totalCount} />
        </Card>
      </div>
    </div>
  );
};
