import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardTitle } from '../../components/CardTitle';
import { getRoute } from '../../config/routes.config';
import {
  DeviceUserPrivilegesFormBody,
  deviceUserPrivilegesForm,
} from '../../forms/device-user-privileges.form';
import {
  useDeviceUserPrivilegesEditSceneQuery,
  useUpdateDeviceUserPrivilegesMutation,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { AppFormGenerator } from '../../releox-engine/react/app-form-generator/AppFormGenerator';
import { BackButtonLink } from '../../releox-engine/react/buttons/LinkButton';
import { SubmitButton } from '../../releox-engine/react/buttons/SubmitButton';

export const DeviceUserPrivilegesEditScene: FC = () => {
  const [searchparams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('DeviceUserPrivilegesEditScene');
  const id = useId();

  const { data } = useDeviceUserPrivilegesEditSceneQuery({ variables: { id } });
  const [updateDeviceUser, { loading }] = useUpdateDeviceUserPrivilegesMutation({
    onCompleted: (d) => {
      if (d.updateDeviceUserPrivileges) {
        toast.success(t('Common:saved'));
        const randomString = Math.random().toString(36).substring(7);
        searchparams.set('updated', randomString);
        setSearchParams(searchparams);
      }
    },
  });

  const handleSubmit = (body: DeviceUserPrivilegesFormBody) => {
    updateDeviceUser({
      variables: {
        id,
        body: {
          hasBasicAccess: body.hasBasicAccess,
          hasDistributionAccess: body.hasDistributionAccess,
        },
      },
    });
  };

  const Footer = (
    <>
      <BackButtonLink to={getRoute('DEVICE_USER_INDEX')} />
      <SubmitButton loading={loading} />
    </>
  );

  return (
    <>
      <CardTitle>{t('title')}</CardTitle>
      <AppFormGenerator
        FooterElement={Footer}
        initialValues={data?.deviceUser}
        wrapperClassNames="space-y-4"
        form={deviceUserPrivilegesForm}
        onSubmit={handleSubmit}
      />
    </>
  );
};
