import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LogMessage } from '../components/LogMessage';
import { TableColumn } from '../components/table/TableColumn';
import { dateFormat } from '../config/date-format';
import { EventIndexSceneQuery } from '../generated/graphql';

export const useEventColumn = () => {
  const { t } = useTranslation('Event');

  const id: TableColumn = {
    field: 'id',
    text: t('id'),
    sort: true,
  };

  const log: TableColumn = {
    field: 'userName',
    text: t('log'),
    formatter: (event: EventIndexSceneQuery['events']['list'][0]) => {
      return <LogMessage event={event} />;
    },
  };

  const deviceTime: TableColumn = {
    field: 'deviceTimestamp',
    text: t('deviceTimestamp'),
    formatter: (x: EventIndexSceneQuery['events']['list'][0]) => {
      return moment(x.deviceTimestamp * 1000).format(dateFormat);
    },
    sort: true,
  };

  return [id, log, deviceTime];
};
