import { useContext } from 'react';
import { useOrderBy } from '../../components/table/use-order-by';
import { usePerPage } from '../../components/table/use-per-page';
import { useSkip } from '../../components/table/use-skip';
import { UserFilterContext } from '../../contexts/UserFilterContext';
import { UserIndexSceneQueryVariables } from '../../generated/graphql';

export const useUserIndexSearchParams = (): UserIndexSceneQueryVariables => {
  const skip = useSkip();
  const take = usePerPage();
  const [orderBy] = useOrderBy();
  const [filter] = useContext(UserFilterContext);

  return {
    skip,
    take,
    where: filter,
    orderBy,
  };
};
