import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const firstName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'firstName',
    label: 'DeviceUser:firstName',
    type: 'text',
  },
};

const lastName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'lastName',
    label: 'DeviceUser:lastName',
    type: 'text',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const digitsOnly = (value: any) => /^\d+$/.test(value);

const pin: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .test('Digits only', 'Validation:numberOnly', digitsOnly)
    .min(4)
    .max(4)
    .required(),
  props: {
    name: 'pin',
    label: 'DeviceUser:pin',
    type: 'password',
  },
};

const tag: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'tag',
    label: 'DeviceUser:tag',
    type: 'text',
  },
};

const pinConfirm: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .oneOf([Yup.ref('pin'), null], 'Validation:match')
    .required(),
  props: {
    name: 'pinConfirm',
    label: 'DeviceUser:pinConfirm',
    type: 'password',
  },
};

const identifier: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'identifier',
    label: 'DeviceUser:identifier',
    type: 'text',
  },
};

const hasBasicAccess: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.boolean().required(),
  props: {
    name: 'hasBasicAccess',
    label: 'DeviceUser:hasBasicAccess',
  },
};

const hasDistributionAccess: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.boolean().required(),
  props: {
    name: 'hasDistributionAccess',
    label: 'DeviceUser:hasDistributionAccess',
  },
};

const accountTypeFilter: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'accountType',
    label: 'DeviceUser:accountType',
    options: [
      { label: 'Common:noOptions', value: '' },
      { label: 'DeviceUser:basic', value: 'basic' },
      { label: 'DeviceUser:distribution', value: 'distribution' },
    ],
  },
};

export const deviceUserInput = {
  firstName,
  lastName,
  tag,
  pin,
  hasBasicAccess,
  hasDistributionAccess,
  accountTypeFilter,
  pinConfirm,
  identifier,
};
