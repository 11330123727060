import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableColumn } from './TableColumn';
import { useOrderBy } from './use-order-by';

type Props = {
  children?: ReactNode;
  column?: TableColumn;
};

export const THeadTD: FC<Props> = ({ children, column }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, orderBy, orderDir] = useOrderBy();
  let text: ReactNode = children;

  const cls = 'px-6 py-3 text-left text-xs font-medium text-gray-color uppercase tracking-wider';

  const isSortable = column?.sort;
  const isActive = orderBy === column?.field;
  const isAsc = orderDir === 'asc';

  if (column) {
    text = column.text;
  }

  let icon: ReactNode = null;

  if (isSortable) {
    if (isActive) {
      if (isAsc) {
        icon = <SortDescendingIcon className="w-4 h-4 inline-block" />;
      } else {
        icon = <SortAscendingIcon className="w-4 h-4 inline-block" />;
      }
    }
  }

  const handleClick = () => {
    if (!isSortable) return;

    searchParams.set('orderBy', column.field);
    if (isActive) {
      if (!isAsc) {
        searchParams.set('orderDir', 'asc');
      } else {
        searchParams.set('orderDir', 'desc');
      }
    } else {
      searchParams.set('orderDir', 'desc');
    }

    searchParams.set('page', '1');

    setSearchParams(searchParams);
  };

  return (
    <td className={[cls, isSortable ? 'text-primary' : ''].join(' ')}>
      {isSortable ? (
        <button type="button" onClick={handleClick} className="cursor-pointer uppercase">
          {text} {icon}
        </button>
      ) : (
        text
      )}
    </td>
  );
};
