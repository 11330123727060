import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TableColumn } from '../components/table/TableColumn';
import { dateFormat } from '../config/date-format';
import { DeviceUserIndexSceneQuery } from '../generated/graphql';

type Data = DeviceUserIndexSceneQuery['deviceUsers']['list'][0];

export const useDeviceUserColumn = () => {
  const { t } = useTranslation('DeviceUser');

  const id: TableColumn = {
    field: 'id',
    text: t('id'),
    sort: true,
  };

  const name: TableColumn = {
    field: 'name',
    text: t('name'),
  };

  const tag: TableColumn = {
    field: 'tag',
    text: t('tag'),
    sort: true,
  };

  const createdAt: TableColumn = {
    field: 'createdAt',
    text: t('Common:createdAt'),
    sort: true,
    formatter: (data: Data) => moment(data.createdAt).format(dateFormat),
  };

  return [id, name, tag, createdAt];
};
