import { useOrderBy } from '../../components/table/use-order-by';
import { usePerPage } from '../../components/table/use-per-page';
import { useSkip } from '../../components/table/use-skip';
import { DepartmentIndexSceneQueryVariables } from '../../generated/graphql';

export const useDepartmentIndexSearchParams = (): DepartmentIndexSceneQueryVariables => {
  const take = usePerPage();
  const [orderBy] = useOrderBy();
  const skip = useSkip();

  return {
    take,
    skip,
    orderBy,
  };
};
