import { ReactNode } from 'react';

type Props = {
  className?: string;
  children: ReactNode;
};

export const Card = ({ children, className }: Props) => (
  <div className={`bg-white ${className} p-4`}>{children}</div>
);
