import { useContext } from 'react';
import { useOrderBy } from '../../components/table/use-order-by';
import { usePerPage } from '../../components/table/use-per-page';
import { useSkip } from '../../components/table/use-skip';
import { DeviceUserFilterContext } from '../../contexts/DeviceUserFilterContext';
import { DeviceUserIndexSceneQueryVariables } from '../../generated/graphql';

export const useDeviceUserIndexSearchParams = (): DeviceUserIndexSceneQueryVariables => {
  const [filter] = useContext(DeviceUserFilterContext);
  const take = usePerPage();
  const [orderBy] = useOrderBy();
  const skip = useSkip();

  return {
    where: {
      firstName: filter.firstName,
      lastName: filter.lastName,
      tag: filter.tag,
      hasBasicAccess: filter.hasBasicAccess,
      hasDistributionAccess: filter.hasDistributionAccess,
    },
    take,
    skip,
    orderBy,
  };
};
