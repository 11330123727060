import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { getRoute } from '../../config/routes.config';
import { UserContext } from '../../contexts/UserContext';
import { UserFormBody, userForm } from '../../forms/user.form';
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUserEditSceneQuery,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { useOnComplete } from '../../hooks/use-on-complete';
import { AppFormGenerator } from '../../releox-engine/react/app-form-generator/AppFormGenerator';
import { Button } from '../../releox-engine/react/buttons/Button';
import { BackButtonLink } from '../../releox-engine/react/buttons/LinkButton';
import { SubmitButton } from '../../releox-engine/react/buttons/SubmitButton';

export const UserEditScene = (): JSX.Element => {
  const { t } = useTranslation('UserEditScene');
  const [user] = useContext(UserContext);

  const onCompleteUpdate = useOnComplete({
    route: 'USER_INDEX',
  });

  const onCompleteDelete = useOnComplete({
    route: 'USER_INDEX',
    notification: 'deleted',
  });

  const id = useId();
  const { data, loading: queryLoading } = useUserEditSceneQuery({ variables: { id } });

  const [deleteUser, { loading: deleteLoading }] = useDeleteUserMutation({
    onCompleted: onCompleteDelete,
  });

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: onCompleteUpdate,
  });

  const handleSubmit = useCallback(
    (body: UserFormBody) => {
      updateUser({ variables: { body: _.omit(body, 'protected', '__typename'), id } });
    },
    [updateUser, id]
  );

  const handleDelete = useCallback(() => {
    if (confirm(t('areYouSure'))) {
      deleteUser({ variables: { id } });
    }
  }, [deleteUser, id, t]);

  const Footer = (
    <>
      <BackButtonLink to={getRoute('USER_INDEX')} />
      <SubmitButton loading={loading || deleteLoading} />
      {!data?.user.protected && id !== user.id ? (
        <>
          <hr className="my-2" />
          <Button type="button" onClick={handleDelete} loading={loading || deleteLoading}>
            {t('Common:deleteText')}
          </Button>
        </>
      ) : null}
    </>
  );

  if (queryLoading) return <Loading />;

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <AppFormGenerator
          initialValues={data?.user}
          FooterElement={Footer}
          wrapperClassNames="space-y-4"
          form={userForm}
          onSubmit={handleSubmit}
        />
      </Card>
    </div>
  );
};
