import { FC, ReactNode } from 'react';
import { TableColumn } from './TableColumn';
import { renderColumn } from './render-column';

type Props = {
  children?: ReactNode;
  column?: TableColumn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: any;
};

export const TBodyTD: FC<Props> = ({ children, column, item }) => {
  let text: ReactNode = children;

  if (column) {
    text = renderColumn(column, item);
  }

  return <td className="px-6 py-4 text-sm td-max-width text-ellipsis overflow-hidden">{text}</td>;
};
