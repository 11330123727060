import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOrderBy = (): [any[], string, string] => {
  const [searchParams] = useSearchParams();

  const orderBy = searchParams.get('orderBy') ?? 'createdAt';
  const orderDir = searchParams.get('orderDir') ?? 'desc';

  return [[{ [orderBy]: orderDir }], orderBy, orderDir];
};
