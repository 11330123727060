export const DeviceUserEditRouterTranslation = {
  fi: {
    DeviceUserEditRouter: {
      info: 'Tiedot',
      pin: 'Pin',
      department: 'Osastot',
      privileges: 'Oikeudet',
    },
  },
};
