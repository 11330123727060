import { useTranslation } from 'react-i18next';
import { TableColumn } from '../components/table/TableColumn';

export const useDepartmentColumns = () => {
  const { t } = useTranslation('Department');
  const name: TableColumn = {
    field: 'name',
    text: t('name'),
    sort: true,
  };

  const id: TableColumn = {
    field: 'id',
    text: t('id'),
    sort: true,
  };

  return [id, name];
};
