import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDeviceUserColumn } from '../../columns/use-device-user.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { DeviceUserFilter } from '../../components/filters/DeviceUserFilter';
import { Pagination } from '../../components/table/Pagination';
import { TBody } from '../../components/table/TBody';
import { TBodyTD } from '../../components/table/TBodyTD';
import { TBodyTR } from '../../components/table/TBodyTR';
import { THead } from '../../components/table/THead';
import { THeadTD } from '../../components/table/THeadTD';
import { THeadTR } from '../../components/table/THeadTR';
import { Table } from '../../components/table/Table';
import { TableStatus } from '../../components/table/TableStatus';
import { getRoute } from '../../config/routes.config';
import { useDeviceUserIndexSceneQuery } from '../../generated/graphql';
import { useDeviceUserIndexSearchParams } from '../../hooks/search-params/use-device-user-index-search-params';

export const DeviceUserIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserIndexScene');
  const columns = useDeviceUserColumn();
  const navigate = useNavigate();

  const variables = useDeviceUserIndexSearchParams();

  const { data, loading } = useDeviceUserIndexSceneQuery({
    variables,
  });

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <DeviceUserFilter />
      </div>
      <div className="col-span-3">
        <Card>
          <ButtonLink className="float-right" to={getRoute('DEVICE_USER_CREATE')}>
            {t('create')}
          </ButtonLink>
          <CardTitle>{t('title')}</CardTitle>
          <Table>
            <THead>
              <THeadTR>
                {columns.map((column) => (
                  <THeadTD column={column} key={column.field} />
                ))}
              </THeadTR>
            </THead>
            <TBody>
              {data?.deviceUsers.list.map((deviceUser) => {
                return (
                  <TBodyTR
                    key={deviceUser.id}
                    onClick={() =>
                      navigate(getRoute('DEVICE_USER_EDIT', { id: deviceUser.id, page: 'info' }))
                    }
                  >
                    {columns.map((column) => (
                      <TBodyTD column={column} item={deviceUser} key={column.field} />
                    ))}
                  </TBodyTR>
                );
              })}
            </TBody>
          </Table>
          <TableStatus data={data?.deviceUsers.list} loading={loading}>
            {t('emptyText')}
          </TableStatus>
          <hr />
          <Pagination total={data?.deviceUsers.totalCount} />
        </Card>
      </div>
    </div>
  );
};
