import { deviceUserInput } from '../inputs/device-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [deviceUserInput.hasBasicAccess, deviceUserInput.hasDistributionAccess];

export const deviceUserPrivilegesForm = createForm(form);

export type DeviceUserPrivilegesFormBody = {
  hasDistributionAccess: boolean;
  hasBasicAccess: boolean;
};
